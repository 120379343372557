(function ($) {
    $.fn.editableTableForm = function () {
        let cnt = this.data('count');
        const addButton = this.find('button[data-cmd="add-row"]');
        const delButton = this.find('button[data-cmd="del-row"]');
        const table = this.find('table');
        const templateRow = table.find('.template-row');
        const $tbody = table.find('tbody');

        addButton.on('click', function () {
            let row = templateRow.clone().removeClass('template-row');
            row.find('input, select, textarea').each(function(i, ele) {
                const $ele = $(ele);
                $ele.attr('id', $ele.attr('id').replace('9999', cnt));
                $ele.attr('name', $ele.attr('name').replace('9999', cnt));
            });
            $tbody.append(row);
            cnt++;
        });
        delButton.on('click', function () {
            // チェックのついた行を削除
            $tbody.find('input[name="_delete"]').filter(":checked").each( function(i, ele){
                $(ele).parents('tr').remove();
            })
        });

        return this;
    };
})(jQuery);
