// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all'
import 'select2'
import 'select2/dist/css/select2.css'
import './stylesheets/application';

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("../channels")
require("admin-lte")
require("jquery")
require("../sky_remote")
require("../auto_expand_form")
require("../editable_table_form")
require("../csv_import_form")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const recruiterSelector = () => {
  const recruiters_selector = $('#recruiters-selector')
  const recruiter_template = recruiters_selector.find('.template').removeClass('template');
  const recruiters = recruiters_selector.find('.recruiters');
  const selection = recruiters_selector.find('.recruiters-selection')
  recruiters_selector
    .on('click', '.add-button', function () {
      const found = selection.find('option:selected');
      if (found.val() !== '') {
        const recruiter = recruiter_template.clone();
        recruiter.find('.recruiter-id').val(found.val());
        recruiter.find('.recruiter-name').text(found.text());
        found.attr('disabled', 'disabled');
        selection.val('').trigger('change');
        recruiters.append(recruiter.show());
      }
    })
    .on('click', '.delete-button', function () {
      const recruiter = $(this).parents('.recruiter')
      const recruiter_id = recruiter.find('.recruiter-id').val();
      recruiter.remove();
      selection
        .find('option[value="' + recruiter_id + '"]')
        .removeAttr('disabled')
        .trigger('change');
    });
};

const applicantSelector = () => {
  const applicants_selector = $('#applicants-selector')
  const applicant_template = applicants_selector.find('.template').removeClass('template');
  const applicants = applicants_selector.find('.applicants');
  const selection = applicants_selector.find('.applicants-selection')
  applicants_selector
    .on('click', '.add-button', function () {
      const found = selection.find('option:selected');
      if (found.val() !== '') {
        const applicant = applicant_template.clone();
        applicant.find('.applicant-id').val(found.val());
        applicant.find('.applicant-name').text(found.text());
        found.attr('disabled', 'disabled');
        selection.val('').trigger('change');
        applicants.append(applicant.show());
      }
    })
    .on('click', '.delete-button', function () {
      const applicant = $(this).parents('.applicant')
      const applicant_id = applicant.find('.applicant-id').val();
      applicant.remove();
      selection
        .find('option[value="' + applicant_id + '"]')
        .removeAttr('disabled')
        .trigger('change');
    });
};

const eventForm = () => {
  function format(d) {
    const yyyy = d.getFullYear();
    const MM = ('0' + (d.getMonth() + 1)).slice(-2);
    const dd = ('0' + d.getDate()).slice(-2);
    const hh = ('0' + d.getHours()).slice(-2);
    const mm = ('0' + d.getMinutes()).slice(-2);
    return yyyy + "-" + MM + "-" + dd + "T" + hh + ":" + mm + ":00";
  }

  const $form = $('.event-form');
  const $start_at = $form.find('#event_start_at');
  const $end_at = $form.find('#event_end_at');
  const $template = $form.find('#event_template');

  /* 開始日時の一時間後に終了日時をセット */
  $start_at.on('input change', function () {
    if ($start_at.val() === "") {
      $end_at.val("");
      return;
    }
    const start_at = new Date($start_at.val());
    const end_at = new Date(start_at.setHours(start_at.getHours() + 1));
    $end_at.val(format(end_at));
  });

  /* テンプレートの場合は開始／終了日時の必須を外す */
  $template.on('change', function(){
    const template = $template.prop('checked');
    if(template) {
      $start_at.removeAttr('required');
      $end_at.removeAttr('required');
    } else {
      $start_at.attr('required', 'required');
      $end_at.attr('required', 'required');
    }
  });
}

document.addEventListener('turbolinks:load', () => {
  $('.select2').select2();
  $('.toast').toast({ autohide: false });
  $('textarea[data-auto-expand="true"]').auto_expand();
  $('.editable-table-form').editableTableForm();
  $('.csv-import-form').csvImportForm();
  recruiterSelector();
  applicantSelector();
  eventForm();
})