(function ($) {
    $.fn.csvImportForm = function () {
        const $form = $(this);
        const $fileField = this.find('#file');
        const $button = this.find('.csv-import-button');
        $button.on('click', function(){
            $fileField.click();
        });
        $fileField.on('change', function(){
            $form.submit();
        })
        return this;
    };
})(jQuery);
