$(document).on('turbolinks:load', function() {
  const $containers = $('.sky-remote-form');
  $containers.on('submit', '.sky-form', function(ev){
    ev.preventDefault();
    const $wrapper = $(this);
    const $container = $wrapper.parent();
    const $form = $wrapper.find('form');
    $.ajax({
      url: $form.attr('action'),
      type: $form.attr('method'),
      data: $form.serialize(),
      dataType: 'json'

    }).done(function(data){
      if(data.success){
        switch (data.type) {
          case 'reload':
            location.reload();
            break;
          case 'redirect':
            Turbolinks.visit(data.url);
            break;
          default:
            $container.html(data.html);
        }
        if(data.notice){
          $('#toast-notice .toast-body').html(data.notice);
          $('#toast-notice').toast('show');
        }
      } else {
        $wrapper.html(data.html).children().contents().unwrap();
        if(data.error){
          $('#toast-error .toast-body').html(data.error);
          $('#toast-error').toast('show');
        }

        $('html, body').animate({scrollTop: $wrapper.offset().top - 70}, 100)
      }

    }).fail(function(jqXHR, status, errorThrown){
      alert('サーバーエラー:' + status);

    }).always(function(){
      $form.find('input[type="submit"]').removeAttr('disabled');
    });
  });
  $containers.on('click', '.div-replace-button', function(ev){
    const $button = $(this);

    $.ajax({
      url: $button.data('url'),
      type: 'GET',
      dataType: 'html'

    }).done(function(data) {
      const $target = $($button.data('target'));
      $target.html(data);
      $target.find('textarea[data-auto-expand="true"]').auto_expand();

      $('html, body').animate({scrollTop: $target.offset().top - 70}, 100)

    }).fail(function(xhr, status){
      console.log(status);
      alert('サーバーエラー: ' + status);
    });
  });
});
