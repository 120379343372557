(function($) {
  $.fn.auto_expand = function() {
    function expandTextAreaHeight(el) {
      const fontWidth = 16; // 一文字あたりの幅
      const fontHeight = 30; // 一文字あたりの高さ
      const wordCount = 200; // 最低文字数

      const calculatedHeight = wordCount / (parseInt(el.clientWidth) / fontWidth) * fontHeight;
      el.style.height = (fontHeight * 3) + "px";
      let wScrollHeight = parseInt(el.scrollHeight) + fontHeight;
      if (wScrollHeight < calculatedHeight) {
        wScrollHeight = calculatedHeight;
      }
      el.style.height = wScrollHeight + "px";
    }

    this.on('input change', function () {
      expandTextAreaHeight(this);
    }).each(function (i, el) {
      expandTextAreaHeight(el);
    });
    return this;
  };
})(jQuery);
